import { IconEye, IconEyeClosed } from 'modules/common/components/_icons/icons'
import { forwardRef, useState } from 'react'
import { TextField, TextFieldProps } from '../TextField/TextField'

export const PasswordTextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const [passwordShown, setPasswordShown] = useState(false)

    const passwordRevealer = (
      <button
        onClick={() => {
          setPasswordShown((prev) => !prev)
        }}
        type="button"
        className="focus:ring-0 focus:ring-offset-0 focus-visible:ring-2 focus-visible:ring-offset-2"
        data-testid="password-revealer"
      >
        {passwordShown ? <IconEyeClosed /> : <IconEye />}
      </button>
    )

    return (
      <div className="relative">
        <TextField
          type={passwordShown ? 'text' : 'password'}
          autoComplete="new-password"
          rightDecorator={passwordRevealer}
          {...props}
          ref={ref}
        />
      </div>
    )
  }
)
