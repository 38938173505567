import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Auth0Error, EnumLoginErrors } from 'modules/auth/types/auth0'
import { trackEvent } from 'modules/common/lib/amplitude/trackEvent'
import { EnumCorporateQueryKeys } from 'modules/corporate/queries/keys'
import { postLoginUser, PostLoginUserProps } from '../api/postLoginUser'
import { clearAccessToken } from '../lib/auth0/token'
import { EnumAuthTrackingEvents } from '../lib/tracking/events'
import { EnumAuthQueryKeys } from '../queries/keys'

interface Props {
  mutationOptions?: UseMutationOptions<
    void,
    AxiosError<Auth0Error>,
    PostLoginUserProps
  >
}

export const usePostLoginUserMutation = ({ mutationOptions }: Props = {}) => {
  const queryClient = useQueryClient()

  return useMutation<void, AxiosError<Auth0Error>, PostLoginUserProps>({
    mutationFn: async (args) => postLoginUser(args),
    ...mutationOptions,
    onMutate: (variables) => {
      trackEvent({
        eventType: EnumAuthTrackingEvents.LOGIN,
      })
      mutationOptions?.onMutate?.(variables)
    },
    onSuccess: (data, variables, context) => {
      queryClient.resetQueries({
        queryKey: [EnumAuthQueryKeys.AUTHENTICATED_USER],
      })
      queryClient.resetQueries({
        queryKey: [EnumCorporateQueryKeys.CORPORATES_STATUS],
      })

      trackEvent({
        eventType: EnumAuthTrackingEvents.LOGIN_SUCCESS,
      })

      mutationOptions?.onSuccess?.(data, variables, context)
    },
    onError: (err, variables, context) => {
      clearAccessToken()

      if (err?.response?.data?.error === EnumLoginErrors.TOO_MANY_ATTEMPTS) {
        trackEvent({
          eventType: EnumAuthTrackingEvents.LOGIN_FAILED_TOO_MANY_ATTEMPS,
        })
      } else if (err?.response?.data?.error === EnumLoginErrors.INVALID_GRANT) {
        trackEvent({
          eventType: EnumAuthTrackingEvents.LOGIN_FAILED_INVALID_CREDENTIALS,
        })
      } else {
        trackEvent({
          eventType: EnumAuthTrackingEvents.LOGIN_FAILED_TECHNICAL_ERROR,
        })
      }

      queryClient.invalidateQueries({
        queryKey: [EnumAuthQueryKeys.AUTHENTICATED_USER],
      })

      mutationOptions?.onError?.(err, variables, context)
    },
  })
}
