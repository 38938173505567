import { nextApiRoutesClient } from 'modules/common/http/nextApiRoutesClient'
import { buildLoginPayload } from '../lib/auth0/payload'

export interface PostLoginUserProps {
  email: string
  password: string
}

export const postLoginUser = async (user: PostLoginUserProps) => {
  const payload = buildLoginPayload(user)

  const { data } = await nextApiRoutesClient.post<void>(
    '/api/auth/login',
    payload
  )

  return data
}
