export enum EnumAuthTrackingEvents {
  EMAIL_VALIDATED = 'Email Validated',
  LOGIN = 'Login',
  LOGIN_SUCCESS = 'Login Success',
  LOGIN_FAILED_INVALID_CREDENTIALS = 'Login Failed (invalid credentials)',
  LOGIN_FAILED_TOO_MANY_ATTEMPS = 'Login Failed (too many attemps)',
  LOGIN_FAILED_TECHNICAL_ERROR = 'Login Failed',
  LOGIN_EMAIL_FORM_STARTED = 'Login Email Form Started',
  LOGIN_PASSWORD_FORM_STARTED = 'Login Password Form Started',
  // Signup mount
  SIGNUP_WEB_FUNNEL_STARTED = 'Web Funnel Started',
  // Signup steps
  SIGNUP_FIRSTNAME_FORM_ENTER = 'FirstName Form Enter',
  SIGNUP_EMAIL_FORM_ENTER = 'Email Form Enter',
  SIGNUP_PASSWORD_FORM_ENTER = 'Password Form Enter',
  SIGNUP_EMAIL_CONFIRMATION_ENTER = 'Email Confirmation Form Enter',
  // Signup fields dirty
  SIGNUP_FIRSTNAME_FORM_STARTED = 'FirstName Form Started',
  SIGNUP_EMAIL_FORM_STARTED = 'Email Form Started',
  SIGNUP_PASSWORD_FORM_STARTED = 'Password Form Started',
  // Signup fields validated
  SIGNUP_FIRSTNAME_FORM_VALIDATED = 'FirstName Form Validated',
  SIGNUP_EMAIL_FORM_VALIDATED = 'Email Form Validated',
  SIGNUP_PASSWORD_FORM_VALIDATED = 'Password Form Validated',
  // Signup sponsorship voucher
  SIGNUP_ENTER_VOUCHER_CODE = '[SignUp] Enter Voucher Code',
  SIGNUP_VALIDATE_VOUCHER_CODE = '[SignUp] Validate Voucher Code',
  // Signup email
  SIGNUP_RESEND_CONFIRMATION_EMAIL = '[SignUp] Resend Confirmation Email',
  SIGNUP_MODIFY_EMAIL = '[SignUp] Modify Email',
  // Signup mutation
  SIGNUP = 'Signup Attempt',
  SIGNUP_SUCCESS = 'Account Created',
  SIGNUP_FAILED_ACCOUNT_ALREADY_EXISTS = 'Signup Failed (account already exists)',
  SIGNUP_FAILED_TECHNICAL_ERROR = 'Signup Failed',
  CREATE_CORPORATE_SUCCESS = 'Create Corporate Success',
  // Change SCA device
  SCA_CHANGE_DEVICE = 'SCA_CHANGE_DEVICE',
}
