import { PublicSponsorshipVoucherApi } from '@blank/api'
import { SignupFormData } from '../hooks/useSignupForm'

export enum EnumSignupSteps {
  NAME = 'name',
  EMAIL = 'email',
  PASSWORD = 'password',
  EMAIL_CONFIRM = 'emailConfirm',
}

export interface SignupData {
  firstname: string | undefined
  lastname: string | undefined
  email: string | undefined
  password: string | undefined
  userAcceptsTerms: boolean
  userAcceptsCommercialSolicitation?: boolean
  publicSponsorshipVoucher?: PublicSponsorshipVoucherApi
}

export type SignupFormField = keyof SignupFormData

export enum EnumSignupType {
  BANK = 'BANK_OFFER',
  STRICTLY_MANAGEMENT_TOOLS = 'STRICTLY_MANAGEMENT_TOOLS',
  DEFAULT_OFFER = 'DEFAULT_OFFER',
}

export enum EnumSignupErrors {
  ACCOUNT_ALREADY_EXISTS = 'ACCOUNT_ALREADY_EXISTS',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
}

export const SPONSORSHIP_VOUCHER_CODE_QUERY_PARAM_KEY = 'sponsorshipVoucherCode'
